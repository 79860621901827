import React from "react";

import ContactModal from "../modal";
import styles from "./style.module.scss";
import buttonShadow from "assets/images/buttonshadow.png";

const Block = ({ setIsModalVisible }) => (
  <div className={styles.block__wrapper}>
    <ContactModal />
    <div className={styles.wrapper__button}>
      <img
        className={styles.button__shadow}
        src={buttonShadow}
        alt="buttonShadow"
      />
      <button onClick={setIsModalVisible}>
        <p>ЗАКАЗАТЬ</p>
      </button>
    </div>
    <div className={styles.wrapper__description}>
      <h1>Порошковая покраска металла — </h1>
      <p className={styles.method}>
        — это метод напыления полимерной порошковой краски на поверхность
        изделия, с последующим формированием покрытия под действием высокой
        температуры.
      </p>
      <div className={styles.description__bottom}>
        <p className={styles.bottom__first}>
          Частицы сухой краски искусственно заряжают электрически, а затем
          наносят на изделие, которое имеет противоположный заряд. <br />
          <br />
          Благодаря этому порошковая краска не осыпается и остается на
          поверхности.
        </p>
        <p className={styles.bottom__second}>
          Затем производится нагрев, который расплавляет краску и создает
          долговечный слой. <br />
          <br />
          Так можно получить качественное стойкое покрытие на изделиях различных
          форм и размеров.
        </p>
      </div>
    </div>
  </div>
);

export default Block;
