import React from "react";

import whiteShadow from "assets/images/buttonshadow.png";
import styles from "./style.module.scss";

const Types = ({ pricesRef }) => {
  const scrollTo = () => {
    pricesRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  return (
    <div className={styles.types__wrapper}>
      <div className={styles.wrapper__content}>
        <div className={styles.content__point}>
          <h1>ВИДЫ РАБОТ:</h1>

          <ul>
            <li>
              Окраска колёсных дисков
              <br />
              (штампованных и литых)
            </li>
            <li>Окраска элементов тормозной системы</li>
            <li>
              Окраска элементов подвески
              <br />
              (рычаги, подрамники)
            </li>
            <li>Окраска мебели и прочих металлоконструкций</li>
          </ul>
        </div>

        <div className={styles.content__button}>
          <button onClick={scrollTo}>УЗНАТЬ ЦЕНЫ</button>
          <img className={styles.types__shadow} src={whiteShadow} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Types;
