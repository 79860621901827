import React from "react";

import styles from "./style.module.scss";

import instagramLogo from "assets/images/instalogo.png";
import facebookLogo from "assets/images/fblogo.png";
import telegram from "assets/images/telegram.png";
import sideLogotype from "assets/images/sideLogo2.png";

const Contacts = () => (
  <div id="contact" className={styles.contact}>
    <div className={styles.contact__toppanel} />

    <div className={styles.contact__wrapper}>
      <img
        className={styles.contact__sidelogo}
        src={sideLogotype}
        alt="sideLogo2.png"
      />
      <div className={styles.contact__text}>
        <h2>Контакты:</h2>

        <a
          className={styles.contact__mail}
          href="mailto: formulatsvetaby.23@gmail.com"
        >
          formulatsvetaby.23@gmail.com
        </a>
        <br />
        <a className={styles.contact__telefon} href="tel:+375 25 796-16-36">
          +375 25 796-16-36
        </a>

        <address>
          <h2>Адрес:</h2>
          <a href="https://www.google.com/maps/place/%D0%98%D0%B2%D0%B5%D0%BA%D0%BE+%D1%86%D0%B5%D0%BD%D1%82%D1%80/@52.136663,23.671288,17z/data=!3m1!4b1!4m6!3m5!1s0x47210994e8d345c5:0x3dcee0431b4917ef!8m2!3d52.1360918!4d23.6705265!16s%2Fg%2F11f62h8fr2?entry=ttu">
            ул.Лейтенанта Рябцева 102д., Брест, Брестская обл. 224004, Беларусь
          </a>
        </address>

        <div className={styles.contact__media}>
          <a
            href="https://www.instagram.com/formula_tsveta_by"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagramLogo} alt="instafram" />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61557042123639"
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebookLogo} alt="facebook" />
          </a>
          <a href="https://t.me/+375257961636" target="_blank" rel="noreferrer">
            <img src={telegram} alt="telegram" />
          </a>
        </div>
      </div>

      <div className={styles.contact__panel}>
        <div className={styles.contact__shadowbar1} />
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2448.9026783237214!2d23.670526499999998!3d52.136091799999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47210994e8d345c5%3A0x3dcee0431b4917ef!2z0JjQstC10LrQviDRhtC10L3RgtGA!5e0!3m2!1sru!2sby!4v1710532756715!5m2!1sru!2sby"
          className={styles.contact__iframe}
        ></iframe>
        <div className={styles.contact__shadowbar2} />
      </div>
    </div>
    <div className={styles.contact__botpanel} />
  </div>
);
export default Contacts;
