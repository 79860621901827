import React from "react";

import styles from "./style.module.scss";
import firstprice from "assets/images/firstprice.png";
import secondprice from "assets/images/secondprice.png";
import thirdprice from "assets/images/thirdprice.png";

const Price = ({ pricesRef }) => (
  <div id="prices" ref={pricesRef} className={styles.price__wrapper}>
    <h2 className={styles.price__heading}>РАСЦЕНКИ</h2>
    <div className={styles.main__container}>
      <div className={styles.price__card}>
        <p className={styles.price__cost}>
          от 220 р.
          <br />
          <span>за комплект</span>
        </p>

        <img className={styles.price__image} src={firstprice} alt="card" />

        <p className={styles.heads__first}>
          <center> Покраска штампованных дисков</center>
        </p>
      </div>

      <div className={styles.price__card}>
        <p className={styles.price__cost}>
          от 260 р.
          <br />
          <span>за комплект</span>
        </p>
        <img className={styles.price__image} src={secondprice} alt="card" />
        <p className={styles.heads__first}>
          <center> Покраска литых дисков</center>
        </p>
      </div>

      <div className={styles.price__card}>
        <p className={styles.price__cost}>
          от 460 р.
          <br />
          <span>за комплект</span>
        </p>
        <img className={styles.price__image} src={thirdprice} alt="card" />
        <p className={styles.heads__first}>
          <center> Покраска литых дисков с алмазной проточкой</center>
        </p>
      </div>
    </div>
  </div>
);

export default Price;
