import styles from "./style.module.scss";

import wheel1 from "assets/images/wheel1.jpg";
import wheel2 from "assets/images/wheel2.jpg";
import wheel3 from "assets/images/wheel3.jpg";
import wheel4 from "assets/images/wheel4.jpg";
import wheel5 from "assets/images/wheel5.jpg";
import wheel6 from "assets/images/wheel6.jpg";
import wheel7 from "assets/images/wheel7.jpg";
import wheel8 from "assets/images/wheel8.jpg";
import wheel9 from "assets/images/wheel9.jpg";
import wheel10 from "assets/images/wheel10.jpg";
import wheel11 from "assets/images/wheel11.jpg";
import wheel12 from "assets/images/wheel12.jpg";
import wheel13 from "assets/images/wheel13.jpg";
import wheel14 from "assets/images/wheel14.jpg";
import wheel15 from "assets/images/wheel15.jpg";

const Wheel = () => (
  <div id="wheel" className={styles.wheel__main}>
    <div className={styles.wheel__container}>
      <header className={styles.wheel__jobs}>НАШИ РАБОТЫ:</header>
      <div className={styles.wheel__images}>
        <div className={styles.wheel__wrapper}>
          <img className={styles.wheel__picture} src={wheel1} alt="wheel" />
        </div>
        <div className={styles.wheel__wrapper}>
          <img className={styles.wheel__picture} src={wheel2} alt="wheel" />
        </div>
        <div className={styles.wheel__wrapper}>
          <img className={styles.wheel__picture} src={wheel3} alt="wheel" />
        </div>
        <div className={styles.wheel__wrapper}>
          <img className={styles.wheel__picture} src={wheel4} alt="wheel" />
        </div>
        <div className={styles.wheel__wrapper}>
          <img className={styles.wheel__picture} src={wheel5} alt="wheel" />
        </div>
        <div className={styles.wheel__wrapper}>
          <img className={styles.wheel__picture} src={wheel6} alt="wheel" />
        </div>
        <div className={styles.wheel__wrapper}>
          <img className={styles.wheel__picture} src={wheel7} alt="wheel" />
        </div>
        <div className={styles.wheel__wrapper}>
          <img className={styles.wheel__picture} src={wheel8} alt="wheel" />
        </div>
        <div className={styles.wheel__wrapper}>
          <img className={styles.wheel__picture} src={wheel9} alt="wheel" />
        </div>
        <div className={styles.wheel__wrapper}>
          <img className={styles.wheel__picture} src={wheel10} alt="wheel" />
        </div>
        <div className={styles.wheel__wrapper}>
          <img className={styles.wheel__picture} src={wheel11} alt="wheel" />
        </div>
        <div className={styles.wheel__wrapper}>
          <img className={styles.wheel__picture} src={wheel12} alt="wheel" />
        </div>
        <div className={styles.wheel__wrapper}>
          <img className={styles.wheel__picture} src={wheel13} alt="wheel" />
        </div>
        <div className={styles.wheel__wrapper}>
          <img className={styles.wheel__picture} src={wheel14} alt="wheel" />
        </div>
        <div className={styles.wheel__wrapper}>
          <img className={styles.wheel__picture} src={wheel15} alt="wheel" />
        </div>
      </div>
    </div>
  </div>
);
export default Wheel;
