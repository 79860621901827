import React from "react";

import styles from "./style.module.scss";

import logoUp from "assets/images/logoUp.png";
import logoDown from "assets/images/logoDown.png";

const TechProcess = () => (
  <div className={styles.tech__container}>
    <div className={styles.panel__up}>
      <img className={styles.logo__up} src={logoUp} alt="logoUp.png" />
    </div>
    <div className={styles.text__process}>
      <p>
        Технологический процесс получения покрытий из порошковых красок включает
        три основные стадии:
      </p>
    </div>
    <div className={styles.panel__down}>
      <img className={styles.logo__down} src={logoDown} alt="logoDown.png" />
    </div>
  </div>
);
export default TechProcess;
