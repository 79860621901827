import React from "react";

import styles from "./style.module.scss";

import sideLogo from "assets/images/sidelogo.png";
import num1 from "assets/images/num1.png";
import num2 from "assets/images/num2.png";
import num3 from "assets/images/num3.png";

const ThreeStages = () => (
  <div className={styles.stages__wrapper}>
    <div className={styles.stages__container}>
      <div className={styles.first__stage}>
        <img src={num1} alt="number" />
        <p className={styles.first__text}>подготовка поверхности;</p>
      </div>

      <div className={styles.second__stage}>
        <img src={num2} alt="number" />
        <p className={styles.second__text}>нанесение порошковой краски;</p>
      </div>

      <div className={styles.third__stage}>
        <img src={num3} alt="number" />
        <p className={styles.third__text}>
          формирование (полимеризация) покрытия.
        </p>
      </div>
    </div>

    <div className={styles.stages__logo}>
      <img src={sideLogo} alt="logo" />
    </div>
  </div>
);

export default ThreeStages;
