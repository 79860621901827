import React, { useState } from "react";
import { IMaskInput } from "react-imask";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import InputLabel from "@mui/material/InputLabel";

import styles from "./style.module.scss";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask="+(375) ##-###-##-##"
      definitions={{
        "#": /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const ContactModal = ({ isModalVisible, setIsModalVisible }) => {
  const [number, setNumber] = useState("(375)");
  const [request, setRequest] = useState("");
  const [isAlarm, setAlarm] = useState(false);

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const closeAlarm = () => {
    setAlarm(false);
  };

  const sendData = async () => {
    if (number.length > 6) {
      await axios.get(
        `https://api.telegram.org/bot6422720033:AAGZZ_hjWs97ZyuFNpJTCJ_Dci8SBCL-D20/sendMessage`,
        {
          params: {
            chat_id: "-4159399092",
            text: `Запрос:\r\n${request}\r\nНомер:\r\n${number}`,
          },
        }
      );

      handleClose();
      setAlarm(true);
    }
  };

  return (
    <>
      <Dialog
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="dialog"
        open={isModalVisible}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Связаться с нами
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className={styles.dialog__content} dividers>
          <FormControl className={styles.content__input} variant="standard">
            <InputLabel className={styles.input__label}>Запрос</InputLabel>
            <Input
              className={styles.input__text_area}
              name="request"
              value={request}
              onChange={(event) => setRequest(event.target.value)}
            />
          </FormControl>

          <FormControl className={styles.content__input} variant="standard">
            <InputLabel className={styles.input__label} required>
              Контактный номер
            </InputLabel>
            <Input
              className={styles.input__text_area}
              required
              value={number}
              name="number"
              inputComponent={TextMaskCustom}
              onChange={(event) => setNumber(event.target.value)}
            />
          </FormControl>

          <Button
            className={styles.content__button}
            variant="contained"
            onClick={sendData}
          >
            Отправить
          </Button>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={isAlarm}
        autoHideDuration={3000}
        onClose={closeAlarm}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          <strong>Ваше сообщение было успешно доставлено</strong>
        </Alert>
      </Snackbar>
    </>
  );
};

export default ContactModal;
