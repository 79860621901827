import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import cn from "classnames";

import menuArrow from "assets/images/menuArrow.svg";

import styles from "./style.module.scss";

const MobileMenu = () => {
  const { pathname } = useLocation();
  const [active, setActive] = useState(false);

  const changeState = () => {
    setActive(!active);
  };

  return (
    <div className={styles.mobile__menu}>
      <button
        type="button"
        className={styles.menu__button}
        onClick={changeState}
      >
        <div
          className={cn(styles.line__top, {
            [styles.line__top__signup]: pathname === "/signup",
            [styles.line__top__active]: active,
          })}
        />
        <div
          className={cn(styles.line__center, {
            [styles.line__center__signup]: pathname === "/signup",
            [styles.line__center__active]: active,
          })}
        />
        <div
          className={cn(styles.line__bottom, {
            [styles.line__bottom__signup]: pathname === "/signup",
            [styles.line__bottom__active]: active,
          })}
        />
      </button>
      <div
        className={
          active ? styles.menu__navigation__active : styles.menu__navigation
        }
      >
        <a href="#wheel">
          <div
            role="presentation"
            className={styles.navigation__line}
            onClick={changeState}
          >
            <h3>Наши работы</h3>
            <img src={menuArrow} alt="arrow" />
          </div>
        </a>
        <a href="#prices">
          <div
            role="presentation"
            className={styles.navigation__line}
            onClick={changeState}
          >
            <h3>Цены</h3>
            <img src={menuArrow} alt="arrow" />
          </div>
        </a>
        <a href="#contact">
          <div
            role="presentation"
            className={styles.navigation__line}
            onClick={changeState}
          >
            <h3>Контакты</h3>
            <img src={menuArrow} alt="arrow" />
          </div>
        </a>
      </div>
    </div>
  );
};

export default MobileMenu;
