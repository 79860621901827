import AOS from "aos";
import React, { useState, useRef } from "react";

import Header from "components/header";
import Block from "components/block";
import TextContent from "components/text-content";
import Types from "components/types";
import Techprocess from "components/tech-process";
import Threestages from "components/three-stages";
import Wheel from "components/wheel";
import Price from "components/price";
import Colors from "components/colors";
import Contacts from "components/contacts";
import Opinions from "components/opinions";
import Footer from "components/footer";
import ContactModal from "components/modal";

import mainStyle from "./App.scss";

function App() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const pricesRef = useRef(null);

  AOS.init({
    once: true,
    offset: 100,
    duration: 800,
  });

  return (
    <div className={mainStyle.main}>
      <Header />
      <Block setIsModalVisible={setIsModalVisible} />
      <TextContent />
      <Types pricesRef={pricesRef} setIsModalVisible={setIsModalVisible} />
      <Techprocess />
      <Threestages />
      <Wheel />
      <Price pricesRef={pricesRef} />
      <Colors />
      <Contacts />
      <Opinions />
      <Footer />
      <ContactModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
    </div>
  );
}

export default App;
