import { gql } from 'apollo-boost';

const opinionsQuery = gql`
  query reviews  {
    reviewsCollection {
    items {
      name,
      date,
      description
    }
  }
}
`;

export default opinionsQuery;
