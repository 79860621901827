import React from "react";

import styles from "./style.module.scss";

import colors from "assets/images/colors.jpg";

const Color = () => (
  <div className={styles.color}>
    <img src={colors} alt="colors" />
  </div>
);
export default Color;
