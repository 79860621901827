import styles from "./style.module.scss";

import endLogo from "assets/images/endLogo.png";
import fbLogo from "assets/images/fblogo.png";
import instaLogo from "assets/images/instalogo.png";
import telegramLogo from "assets/images/telegram.png";

const Footer = () => (
  <div className={styles.end}>
    <div className={styles.end__toppanel} />

    <div className={styles.end__description}>
      <div className={styles.end__logo}>
        <img src={endLogo} alt="logo" />
        <p>{`${new Date().getFullYear()} `}</p>
      </div>

      <div className={styles.end__title}>
        <div className={styles.title__socials}>
          <p>Соц.сети:</p>
          <div className={styles.end__logotypes}>
            <a
              href="https://www.instagram.com/formula_tsveta_by/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instaLogo} alt="instagram" />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61557042123639&mibextid=ZbWKwL"
              target="_blank"
              rel="noreferrer"
            >
              <img src={fbLogo} alt="facebook" />
            </a>
            <a
              href="https://t.me/+375257961636"
              target="_blank"
              rel="noreferrer"
            >
              <img src={telegramLogo} alt="telegram" />
            </a>
          </div>
        </div>

        <p className={styles.end__rights}>
          ©{`${new Date().getFullYear()} `} Авторские права: formulatsveta.by
        </p>
      </div>

      <div className={styles.end__column}>
        <h2>Контакты:</h2>
        <a
          className={styles.end__mail}
          href="mailto:formulatsvetaby.23@gmail.com"
        >
          {" "}
          formulatsvetaby.23@gmail.com
        </a>
        <br />
        <a className={styles.end__phone} href="tel:+375 25 796-16-36">
          +375 25 796-16-36
        </a>
        <h2>Адрес:</h2>
        <address>
          <a
            href="https://www.google.com/maps/place/%D0%98%D0%B2%D0%B5%D0%BA%D0%BE+%D1%86%D0%B5%D0%BD%D1%82%D1%80/@52.136663,23.671288,17z/data=!3m1!4b1!4m6!3m5!1s0x47210994e8d345c5:0x3dcee0431b4917ef!8m2!3d52.1360918!4d23.6705265!16s%2Fg%2F11f62h8fr2?entry=ttu
        "
          >
            ул.Лейтенанта Рябцева 102д., Брест, Брестская обл. 224004, Беларусь
          </a>
        </address>
      </div>
    </div>
  </div>
);

export default Footer;
