import React from 'react';

import styles from './style.module.scss';

const NoMatch = () => (
  <div className={styles.not__found}>
    <h2>Page Not Found</h2>
  </div>
);

export default NoMatch;
