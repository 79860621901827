import React from "react";
import { useQuery } from "@apollo/client";
import Carousel from "react-material-ui-carousel";

import opinionsQuery from "queries/opinions";
import Loader from "../loader";
import arrowIcon from "assets/images/arrow.svg";

import people from "assets/images/people.png";
import styles from "./style.module.scss";

const Opinions = () => {
  const { loading, data } = useQuery(opinionsQuery);
  const paddingWidth = window.innerWidth <= 1280 ? 240 : 400;
  const cardsIndex = Math.floor((window.innerWidth - paddingWidth) / 325);
  const cardsCount = cardsIndex > 0 ? cardsIndex : 1;

  return (
    <div className={styles.opinions__wrapper}>
      <header>Отзывы наших клиентов:</header>
      <div className={styles.opinions__main}>
        {loading ? (
          <Loader />
        ) : (
          <Carousel
            height={window.innerWidth > 700 ? 400 : 240}
            animation="fade"
            navButtonsAlwaysVisible
            autoPlay={true}
            className={styles.carousel__wrapper}
            navButtonsWrapperProps={{
              style: {
                width: "45px",
              },
            }}
            navButtonsProps={{
              style: {
                margin: 0,
                background: "none",
              },
            }}
            NextIcon={
              <img
                src={arrowIcon}
                className={styles.next__arrow}
                alt="arrowNext"
              />
            }
            PrevIcon={
              <img
                src={arrowIcon}
                className={styles.prev__arrow}
                alt="arrowPrev"
              />
            }
            indicatorContainerProps={{
              style: {
                display: "none",
              },
            }}
          >
            {data?.reviewsCollection?.items &&
              Array(Math.ceil(data.reviewsCollection.items.length / cardsCount))
                .fill()
                .map((item, i) => (
                  <div key={i} className={styles.collection__wrapper}>
                    {data.reviewsCollection.items.map((item, index) => {
                      if (
                        index + 1 <= (i + 1) * cardsCount &&
                        index + 1 > i * cardsCount
                      ) {
                        return (
                          <div
                            key={`${i} + ${index}`}
                            className={styles.wrapper__common}
                          >
                            <img
                              className={styles.opinions__person}
                              src={people}
                              alt="people"
                            />
                            <div className={styles.wrapper__card}>
                              <p className={styles.opinions__user}>
                                {item.name}
                              </p>
                              <p className={styles.opinions__date}>
                                {item.date}
                              </p>
                              <div className={styles.opinion__desctiption}>
                                <p className={styles.opinions__text}>
                                  {item.description}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        <></>;
                      }
                    })}
                  </div>
                ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};

export default Opinions;
