import React from "react";

import styles from "./style.module.scss";
import logo from "assets/images/logo.png";
import instagramLogo from "assets/images/instalogo.png";
import facebookLogo from "assets/images/fblogo.png";
import telegram from "assets/images/telegram.png";
import hoverEffect from "assets/images/hoverEffect.png";
import MobileMenu from "components/mobile-menu";

const Header = () => (
  <div className={styles.header}>
    <div className={styles.header__wrapper}>
      <div className={styles.wrapper__logo}>
        <a href="https://formulatsveta.by">
          <img className={styles.header__mainlogo} src={logo} alt="logo2.png" />
        </a>
        <h2>ФОРМУЛА ЦВЕТА</h2>
      </div>

      {window.innerWidth > 425 && (
        <div className={styles.first__container}>
          <a href="#wheel">Наши работы</a>
          <a href="#prices">Цены</a>
          <a className={styles.header__hover} href="#contact">
            <div className={styles.header__content}>
              <img
                className={styles.hover__image}
                src={hoverEffect}
                alt="hover"
              ></img>
              <a href="tel:+375 25 796-16-36" className={styles.hover__number}>
                +375 25 796-16-36{" "}
              </a>

              <br />
              <br />
              <a
                href="mailto:formulatsvetaby.23@gmail.com"
                className={styles.hover__text}
              >
                formulatsvetaby.23@gmail.com
              </a>
            </div>
            Контакты
          </a>
        </div>
      )}

      {window.innerWidth > 425 ? (
        <div className={styles.second__container}>
          <a
            href="https://www.instagram.com/formula_tsveta_by"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={styles.header__insta}
              src={instagramLogo}
              alt="instagram"
            />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61557042123639"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={styles.header__facebook}
              src={facebookLogo}
              alt="facebook"
            />
          </a>
          <a href="https://t.me/+375257961636" target="_blank" rel="noreferrer">
            <img src={telegram} alt="telegram" />
          </a>
        </div>
      ) : (
        <MobileMenu />
      )}
    </div>
  </div>
);

export default Header;
