import React from 'react';
import Lottie from 'lottie-react';

import loader from './loader.json';
import styles from './style.module.scss';

const Loader = () => (
  <div className={styles.loader}>
    <Lottie
      className={styles.loader__spinner}
      animationData={loader}
    />
  </div>
);

export default Loader;
