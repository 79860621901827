import React from "react";

import styles from "./style.module.scss";

import ftSveta from "assets/images/ftSveta.png";

const TextContent = () => (
  <div className={styles.text__wrapper}>
    <div className={styles.wrapper__description}>
      <div>
        <header>ПОЧЕМУ МЫ?</header>
        <p className={styles.text__heading}>
          Порошковая окраска имеет ряд преимуществ по сравнению с традиционными
          жидкими покрытиями, например:
        </p>
      </div>

      <div className={styles.column__wrapper}>
        <div className={styles.column}>
          <img src={ftSveta} alt="logo" />
          <h3>ДОЛГОВЕЧНОСТЬ</h3>

          <p>
            - Слой порошковой краски обладает стойкостью по отношению к
            агрессивной среде (химикаты, кислоты, бензин, соли, все виды масел,
            газы, растворители, УФ-излучение, абразивное стирание). При
            порошковой покраске образуется ударопрочное антикоррозийное
            покрытие, которое отличается высокой термостойкостью.
          </p>
        </div>

        <div className={styles.column}>
          <img src={ftSveta} alt="logo" />
          <h3>ЭСТЕТИКА</h3>

          <p>
            - Довольно широкая цветовая гамма. доступно более 5 000 оттенков и
            фактур. Благодаря полимерному покрытию можно получить красивую
            металлическую поверхность, благородный оттенок под старину и много
            других эффектов. Еще одно преимущество в том, что в процессе
            покраски вы легко можете контролировать уровень блеска.
          </p>
        </div>

        <div className={styles.column}>
          <img src={ftSveta} alt="logo" />
          <h3>ЭКОЛОГИЧНОСТЬ</h3>

          <p>
            - Порошковая краска не содержит растворителей и вредных веществ,
            которые выделяются при использовании жидких красок. Поэтому
            порошковая краска более безопасна для окружающей среды и работников.
          </p>
        </div>
      </div>
    </div>
  </div>
);
export default TextContent;
